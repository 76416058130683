.admin-dashboard {
    
    padding: 20px;
}

.tabs-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; /* Add margin to separate tabs from cards */
}

.tabs {
    display: flex;
}

.tabs button {
    padding: 10px;
    margin-right: 10px; /* Add margin between tabs */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #e0e0e0;
    transition: background 0.2s;
}

.tabs button.active {
    background: #3f51b5;
    color: #fff;
}

.tabs button:hover:not(.active) {
    background: #d0d0d0;
}

.dashboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    background: linear-gradient(135deg, #f3f8fe, #ffffff);
}

.card {
    position: relative;
    background-color: white;
    border: 2px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s, border-color 0.2s;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card:hover {
    transform: scale(1.05);
    border-color: #ff6347;
}

.card h3 {
    margin: 0;
    font-size: 1.3em;
    font-family: 'Arial', sans-serif;
}

.bubble-number {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.5em;
    font-weight: bold;
    color: #57927e;
    background-color: #f0f0f0;
    padding: 8px 15px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
    font-size: 4em;
    color: #e83e8c
}

.men .icon {
    color: #007bff; /* Update color for men */
}

.kids .icon {
    color: #ffc107; /* Update color for kids */
}

.fabric .icon {
    color: #28a745; /* Update color for fabric */
}

.home-accessories .icon {
    color: #17a2b8; /* Update color for home accessories */
}

.card.women .women-image {
    background-image: url('../../images/women clothing.png');
    background-size: contain; /* Adjusted property */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: center; /* Center the image */
    width: 100%;
    height: 100%;
}

.card.women:hover {
    background-color: #e83e8c; /* Pink color on hover */
    transition: background-color 0.5s ease; /* Sliding transition */
}

.card.women:hover .icon {
    color: white; /* Change icon color to white on hover */
}

.card.women:hover h3 {
    color: white; /* Change text color to white on hover */
    transition: color 0.5s; /* Add transition effect */
}

.card.men .men-image {
    background-image: url('../../images/Men clothing.png');
    background-size: contain; /* Adjusted property */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: center; /* Center the image */
    width: 100%;
    height: 100%;
}

/* Add similar hover effects for the men's card */
.card.men:hover {
    background-color: #007bff; /* Blue color on hover */
    transition: background-color 0.5s ease; /* Sliding transition */
}

.card.men:hover .icon {
    color: white; /* Change icon color to white on hover */
}

.card.men:hover h3 {
    color: white; /* Change text color to white on hover */
    transition: color 0.5s; /* Add transition effect */
}

.card.kids .kids-image {
    background-image: url('../../images/kids clothing.png');
    background-size: contain; /* Adjusted property */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: center; /* Center the image */
    width: 100%;
    height: 100%;
}

.card.kids:hover {
    background-color: #ffc107; /* Yellow color on hover */
    transition: background-color 0.5s ease; /* Sliding transition */
}

.card.kids:hover .icon {
    color: white; /* Change icon color to white on hover */
}

.card.kids:hover h3 {
    color: white; /* Change text color to white on hover */
    transition: color 0.5s; /* Add transition effect */
}

.card.fabric .fabric-image {
    background-image: url('../../images/fabric clothing.png');
    background-size: contain; /* Adjusted property */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: center; /* Center the image */
    width: 70%;
    height: 100%;
}

.card.fabric:hover {
    background-color: #28a745; /* Green color on hover */
    transition: background-color 0.5s ease; /* Sliding transition */
}

.card.fabric:hover .icon {
    color: white; /* Change icon color to white on hover */
}

.card.fabric:hover h3 {
    color: white; /* Change text color to white on hover */
    transition: color 0.5s; /* Add transition effect */
}

.card.home-accessories .home-image {
    background-image: url('../../images/home acessories.png');
    background-size: contain; /* Adjusted property */
    background-repeat: no-repeat; /* Ensure the image is not repeated */
    background-position: center; /* Center the image */
    width: 70%;
    height: 100%;
}

.card.home-accessories:hover {
    background-color: #17a2b8; /* Blueish color on hover */
    transition: background-color 0.5s ease; /* Sliding transition */
}

.card.home-accessories:hover .icon {
    color: white; /* Change icon color to white on hover */
}

.card.home-accessories:hover h3 {
    color: white; /* Change text color to white on hover */
    transition: color 0.5s; /* Add transition effect */
}

.card.fabric {
    grid-column: 1 / 2 !important; /* First column of the second row */
}

.card.home-accessories {
    grid-column: 2 / 3 !important; /* Second column of the second row */
}

.tabs-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; /* Add margin to separate tabs from cards */
    width: 100%; /* Ensure tabs take up full width */
}

.tabs {
    display: flex;
    flex-grow: 1; /* Tabs should take up all available space */
}

.tabs button {
    padding: 10px;
    margin-right: 10px; /* Add margin between tabs */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #e0e0e0;
    transition: background 0.2s;
    flex-grow: 1; /* Each tab should take up equal space */
}

.tabs button.active {
    background: #3f51b5;
    color: #fff;
}

.tabs button:hover:not(.active) {
    background: #d0d0d0;
}

.tab-content {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-logs {
    grid-column: 1 / -1 !important; /* Span the entire grid */
}

.user-logs .user-logs-content {
    width: 100%; /* Ensure content takes full width */
    max-width: none; /* Remove max-width limitation */
    padding: 20px; /* Add padding */
    background: #fff; /* Add background color */
    border-radius: 8px; /* Add border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.user-logs table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: fixed; /* Ensure table takes full width */
}

.user-logs th, .user-logs td {
    border: 1px solid #ddd;
    font-size: 16px !important;
    color: black !important;
    padding: 10px;
    text-align: left;
    word-wrap: break-word; /* Allow long content to wrap */
}

.user-logs th {
    background: linear-gradient(90deg, #3f51b5, #90caf9 99%);
    color: white !important;
}

/* Adjustments for each mini-tab */
.user-logs .mini-tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; /* Add margin to separate mini-tabs from tables */
    width: 100%; /* Ensure mini-tabs take up full width */
}

.user-logs .mini-tabs button {
    padding: 10px;
    margin-right: 10px; /* Add margin between mini-tabs */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: #e0e0e0;
    transition: background 0.2s;
    flex-grow: 1; /* Each mini-tab should take up equal space */
}

.user-logs .mini-tabs button.active {
    background: #1bcfb4;
    color: #fff;
}

.user-logs .mini-tabs button:hover:not(.active) {
    background: #d0d0d0;
}

.user-logs .user-logs-content {
    width: 100%; /* Ensure content takes full width */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.user-logs .user-logs-content table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    table-layout: fixed; /* Ensure table takes full width */
}


.user-logs .user-logs-content th,
.user-logs .user-logs-content td {
    font-size: 16px !important; /* Adjust the font size as needed */
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    word-wrap: break-word; /* Allow long content to wrap */
}

.user-logs .user-logs-content th {
    background-color: #f2f2f2;
}

.mini-tabs button.block-button {
    padding: 8px 16px; /* Adjust padding for button size */
    background-color: #ff5252; /* Red background color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Add border radius */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add transition effect */
}

/* Hover effect for blocking button */
.mini-tabs button.block-button:hover {
    background-color: #ff0000; /* Darker red color on hover */
}

.user-logs .user-logs-content th {
    background-color: #4CAF50; /* Green background color for table headers */
    color: #fff; /* Text color for table headers */
}

.user-logs .user-logs-content th,
.user-logs .user-logs-content td {
    font-size: 16px !important; /* Adjust the font size as needed */
    border: 1px solid #ddd; /* Light gray border color for table cells */
    padding: 10px;
    text-align: left;
    word-wrap: break-word; /* Allow long content to wrap */
}

.user-logs .user-logs-content tr:nth-child(even) {
    background-color: #E0E0E0; /* Light gray background color for even table rows */
}

.user-logs .user-logs-content tr:nth-child(odd) {
    background-color: #F5F5F5; /* Slightly darker gray background color for odd table rows */
}

.user-logs .mini-tabs button {
    padding: 10px 20px; /* Adjust padding for button size */
    background: linear-gradient(90deg, #90caf9, #3f51b5 99%); /* Blue background color for mini-tabs buttons */
    color: #fff; /* Text color for mini-tabs buttons */
    border: none; /* Remove border */
    border-radius: 4px; /* Add border radius */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add transition effect */
}

/* Hover effect for mini-tabs buttons */
.user-logs .mini-tabs button:hover {
    background-color: #1976D2; /* Darker blue color on hover */
}

.icon-container {
    display: flex;
    gap: 30px;
  }

  .green-text {
    color: green;
  }

  .mini-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .mini-tabs button {
    position: relative;
    padding-right: 30px; /* Space for the bubble */
    font-size: 16px;
  }
  
  .mini-tabs .bubble {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #1bcfb4;
    color: white;
    border-radius: 50%;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
  }

  .mini-tabs button.active .bubble {
    background-color: #3f51b5;
  }

  .dropdown-logs {
    grid-column: 1 / -1 !important; /* Span the entire grid */
  }
  
.dropdown-tabs {
    display: flex;
    overflow-x: auto; /* Add horizontal scrollbar if tabs overflow */
    width: 100%; /* Ensure the container takes full width */
  }
  

  .dropdown-tabs button {
    border: none;
    background: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    color: #333;
    transition: border-bottom-color 0.3s ease; /* Add transition for the underline effect */
    border-bottom: 2px solid transparent; /* Initially, no underline */
  }
  
  .dropdown-tabs button.active {
    color: #3f51b5; /* Change text color for active tab */
    border-bottom-color: #3f51b5; /* Add underline for active tab */
  }
  
  .dropdown-tabs button:hover {
    color: #3f51b5; /* Change text color on hover */
  }
  
  .dropdown-tabs button:not(.active):hover {
    border-bottom-color: #3f51b5; /* Add underline on hover for non-active tabs */
  }

  .category-list-container {
    margin: 0 auto;
    max-width: 800px; /* Increased max-width for better readability */
    margin-top: 8%;
    padding: 20px; /* Added padding for spacing */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 2%;
  }
  
  .table-container {
    margin-top: 30px; /* Reduced margin for compact layout */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px; /* Increased padding for better spacing */
    font-size: 14px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  th {
    background: linear-gradient(90deg, #3f51b5, #90caf9 99%); /* Primary color for header */
    color: #ffffff; /* White text color for header */
    text-transform: uppercase; /* Uppercase text for header */
    letter-spacing: 1px; /* Increased letter spacing for header */
  }
  
  tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Alternating row colors */
  }
  
  tbody tr:hover {
    background-color: #e0e0e0; /* Hover effect for rows */
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .input-container input {
    flex: 1; /* Full width input */
    padding: 10px; /* Padding for input */
    border: 1px solid #ccc; /* Border for input */
    border-radius: 4px; /* Rounded corners for input */
    margin-right: 10px; /* Spacing between input and button */
  }
  
  .input-container .fa-plus {
    color: #007bff;
    cursor: pointer;
    font-size: 1.5em; /* Increased size for better visibility */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .input-container .fa-plus:hover {
    color: #0056b3; /* Darker color on hover */
  }
  
  .fa-trash {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }
  
  .fa-trash:hover {
    color: darkred; /* Darker red on hover */
  }

  .product-codes-container {
    margin: 0 auto;
    max-width: 800px; /* Increased max-width for better readability */
    margin-top: 8%;
    padding: 20px; /* Added padding for spacing */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 2%;
  }
  
  .product-codes-table-container {
    margin-top: 30px; /* Reduced margin for compact layout */
  }
  
  .product-codes-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .product-codes-table th,
  .product-codes-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px; /* Increased padding for better spacing */
    font-size: 14px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .product-codes-table th {
    background: linear-gradient(90deg, #3f51b5, #90caf9 99%); /* Primary color for header */
    color: #ffffff; /* White text color for header */
    text-transform: uppercase; /* Uppercase text for header */
    letter-spacing: 1px; /* Increased letter spacing for header */
  }
  
  .product-codes-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Alternating row colors */
  }
  
  .product-codes-table tbody tr:hover {
    background-color: #e0e0e0; /* Hover effect for rows */
  }
  
  .product-codes-container .input-container {
    display: flex;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .product-codes-container .input-container input {
    flex: 1; /* Full width input */
    padding: 10px; /* Padding for input */
    border: 1px solid #ccc; /* Border for input */
    border-radius: 4px; /* Rounded corners for input */
    margin-right: 10px; /* Spacing between input and button */
  }
  
  .product-codes-container .input-container .fa-plus {
    color: #007bff;
    cursor: pointer;
    font-size: 1.5em; /* Increased size for better visibility */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .product-codes-container .input-container .fa-plus:hover {
    color: #0056b3; /* Darker color on hover */
  }
  
  .product-codes-container .fa-trash {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }
  
  .product-codes-container .fa-trash:hover {
    color: darkred; /* Darker red on hover */
  }

  .sample-types-container {
    margin: 0 auto;
    max-width: 800px; /* Increased max-width for better readability */
    margin-top: 8%;
    padding: 20px; /* Added padding for spacing */
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 2%;
  }
  
  .sample-types-table-container {
    margin-top: 30px; /* Reduced margin for compact layout */
  }
  
  .sample-types-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sample-types-table th,
  .sample-types-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 12px; /* Increased padding for better spacing */
    font-size: 14px;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .sample-types-table th {
    background: linear-gradient(90deg, #3f51b5, #90caf9 99%); /* Primary color for header */
    color: #ffffff; /* White text color for header */
    text-transform: uppercase; /* Uppercase text for header */
    letter-spacing: 1px; /* Increased letter spacing for header */
  }
  
  .sample-types-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Alternating row colors */
  }
  
  .sample-types-table tbody tr:hover {
    background-color: #e0e0e0; /* Hover effect for rows */
  }
  
  .sample-types-container .input-container {
    display: flex;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 20px; /* Added margin for spacing */
  }
  
  .sample-types-container .input-container input {
    flex: 1; /* Full width input */
    padding: 10px; /* Padding for input */
    border: 1px solid #ccc; /* Border for input */
    border-radius: 4px; /* Rounded corners for input */
    margin-right: 10px; /* Spacing between input and button */
  }
  
  .sample-types-container .input-container .fa-plus {
    color: #007bff;
    cursor: pointer;
    font-size: 1.5em; /* Increased size for better visibility */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .sample-types-container .input-container .fa-plus:hover {
    color: #0056b3; /* Darker color on hover */
  }
  
  .sample-types-container .fa-trash {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }
  
  .sample-types-container .fa-trash:hover {
    color: darkred; /* Darker red on hover */
  }

  .sub-categories-container {
    margin: 0 auto;
    max-width: 600px;
    margin-top: 8%;
  }
  
  .sub-categories-table-container {
    margin-top: 50px;
  }
  
  .sub-categories-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sub-categories-table th,
  .sub-categories-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
  
  .sub-categories-table th {
    background-color: #f2f2f2;
  }
  
  .sub-categories-input-container {
    position: absolute;
    top: 30%;
    left: 3%;
  }
  
  .sub-categories-container .input-container {
    display: flex;
    align-items: center;
    margin-top: 3%;
  }
  
  .sub-categories-container .input-container input {
    margin-right: 5px;
  }
  
  .sub-categories-container .input-container .fa-plus {
    color: #007bff;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .sub-categories-container .fa-trash {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }

  .product-types-container {
    margin: 0 auto;
    max-width: 600px;
    margin-top: 8%;
  }
  
  .product-types-table-container {
    margin-top: 50px;
  }
  
  .product-types-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .product-types-table th,
  .product-types-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }
  
  .product-types-table th {
    background-color: #f2f2f2;
  }
  
  .product-types-input-container {
    position: absolute;
    top: 30%;
    left: 3%;
  }
  
  .product-types-container .input-container {
    display: flex;
    align-items: center;
    margin-top: 3%;
  }
  
  .product-types-container .input-container input {
    margin-right: 5px;
  }
  
  .product-types-container .input-container .fa-plus {
    color: #007bff;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .product-types-container .fa-trash {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    transition: color 0.3s ease;
  }