.header-gradient {
    font-weight: bold;
    text-align: center;
    font-size: 3em;
    background: linear-gradient(to right, rgb(59, 130, 246), rgb(29, 78, 216));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* Mens.css */
.category-button {
  background-color: blue; /* Blue background */
  color: white; /* White text */
  border: none; /* Removes the border */
  padding: 10px 20px; /* Adds some padding */
  margin: 5px; /* Adds some space between the buttons */
  border-radius: 5px; /* Rounds the corners */
  cursor: pointer; /* Changes cursor to pointer on hover */
  display: flex; /* Aligns text in the center (with justify-content) */
  justify-content: space-between;
  width: 100%; /* Makes the buttons expand to the container's width */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Adds a subtle shadow */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.category-button:hover {
  background-color: darkblue; /* Darker blue on hover */
}

.category-button.selected {
  background-color: navy; /* Even darker blue for selected button */
}

.product-type-button {
  background-color: cyan;
  color: black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

.product-type-button:hover {
  background-color: gray;
}

.product-type-button.active {
  background-color: lightblue;
  color: white;
  border-color: lightblue;
}