@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.my-element-inter {
  font-family: 'Inter', sans-serif;
  color:gray;
  font-size: 18px;
}
.fontinter{
  font-family: 'Inter', sans-serif;
}
.imagesize{
padding-left: 50%;
}
.products-container {
    display: flex;
    justify-content: left;
    align-items: left;
    padding-left:2px;
    flex-wrap: wrap;
 }
 .input-field {
  border: 1px solid gray;
  padding: 8px;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  margin-top: 4px;
}

.products-container .product-card {
    width: 200px;
    height: 250px;
    margin: 20px;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    box-shadow: 7px 7px 5px #e4e4e4;
    position: relative;
}


.products-container .product-card .product-img {
    width: 100%;
    height: 200px;
}

.products-container .product-card .product-img img {
    width: 100%;
    height: 100%;
}

.products-container .product-card .product-name {
    display: block;
    width: 100%;
    color: #f57224;
    margin-top: 5px;
}

.products-container .product-card .product-price {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
}
 a:hover{
    text-decoration:none;
    outline:none;
  }
  .centertable {
    margin-top:25px;
    margin-left: 30px;
    margin-bottom: 20px;
    margin-right: 20px;
   }
  
body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter',serif;
  display: grid;
}
.container {
  width: 100%;
	padding-right:5px;
	padding-left:5px;
	margin-right:auto;
	margin-left:auto
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color:#f44336;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 100px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 10px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 15px;
  font-weight: 600;
  line-height:40px;
  position: relative;
  display: block;
  color: gray;
  margin:13px 10px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle > .container > .row {
  display: flex;
  align-items: center; /* This ensures vertical alignment in the center */
  justify-content: space-between; /* This pushes the logo and nav to opposite ends */
}

.header__middle__logo, .header__middle__menus {
  flex: 1; /* Allows both elements to grow and occupy equal space if needed */
}

/* Specific adjustments for the nav items to align right */
.header__middle__menus {
  display: flex;
  justify-content: flex-end; /* Aligns the nav items to the right */
}

.header__middle__menus .main-nav ul {
  display: flex;
  align-items: center; /* Aligns nav items vertically in the center */
  padding-left: 0; /* Removes default padding */
}

.header__middle__menus .main-nav ul li {
  padding: 0 10px; /* Adjust based on your design */
}

.sub__menus__arrows {
  position: relative;
}

.sub__menus {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  color: white;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(145, 10, 10, 0.2);
  z-index: 1;
  min-width: 160px;
}


@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  /* margin: 7px 27px; */
}
.menu-item:hover > a{
  color:rgb(20, 114, 215) !important;
}
.menu-item .sub__menus li:hover a{
  color: rgb(13, 100, 206) !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}

.boxuniq{
  width: 6%;
  padding: 5px 20px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.textboxforms{
  width: 130%;
  padding: 7px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.labelSticker {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  text-align: center;
}


@media screen and (max-width: 600px){
  .textboxform,.sbutton{
    width: 100%;
    margin-top: 0;
  }
}

.dropdownbox{
  width: 3px;
}
/*table*/
@media print {
  @page{ 
   size:6in 4.2in
   
  }

  #table-component {
    font-size: 10px;
  }

  table {
    border-collapse: collapse;
    width: 70%;
    height: 70%;
  }
  th, td {
    border: 1px solid black;
    text-align: left;
    font-family: 'Inter', sans-serif;
  }
}

.table, td, th {
  border: 1px solid black;
  font-family: 'Inter', sans-serif;
    }

 table {
border-collapse: collapse;
width: 70%;
height: 70%;

}
td {
  text-align: center;
  font-size:10px;
  font-weight:bolder;
}

/*filter*/
.filter-products-main-box{
  width:100%;  display: flex;  

}

@media(max-width: 540px){
  .filter-products-main-box{
    flex-direction: column;
  }
}

.filter-products-main-box .my-products{
  flex:3.3;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;  
  
}

@media(max-width: 540px){
  .filter-products-main-box .my-products{
    width: 100%;
    margin-top: 5px; 
  }
}

.filter-box{
  display: flex;
  flex-direction: column;
  /* border: 1px solid black; */
  /* border: 1px solid red; */
  flex: 0.7;
  padding-left: 15px;
 
}


@media(max-width: 540px){
  .filter-box{
    width:50px;
    padding-left: 5px;

  }
}

.filter-box h6{
  font-weight: 600;
  margin-bottom: 0px;
}

.filter-box span{
  cursor: pointer;
  margin:7px 0px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color:grey;
  font-weight:600;
}

.filter-box span.TOPS{
  color: #e00a02;
}

.filter-box span.SHIRT{
  color: #e00a02;
}

.filter-box span.DRESS{
  color: #e00a02;
}

.filter-box span.JACKET{
  color: #e00a02;
}
.filter-box span.OUTERWEAR{
  color: #e00a02;
}
.filter-box span.SHAKET{
  color: #e00a02;
}
.filter-box span.JUMPSUIT{
  color: #e00a02;
}
.filter-box span.ROMPER{
  color: #e00a02;
}
.filter-box span.TROUSER{
  color: #e00a02;
}

.filter-box span.SHORTS{
  color: #e00a02;
}
.filter-box span.SET{
  color: #e00a02;
}
.filter-box span.JOGGER{
  color: #e00a02;
}

/*pagination*/
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 25px;
  height: 25px;
  font-family: inherit;
  font-weight: 600;
  font-size: 12px;
  margin: 0 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background:lightslategray;
  color: #eeeeee;
  border-color: #eee;
  
}

.pagination button.active {
  font-weight: 900;
  border-color: #101010;
  background: #ffe400;
  color: #101010;
}

body {
  font-family: 'Poppins', sans-serif;
}