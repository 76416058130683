.gendercategory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

h1 {
  flex: 1;
  font-weight: 600; /* Makes the title bold */
  text-align: center; /* Center-aligns the title */
  color: #007bff; /* Changes the color to a shade of blue */
  /* text-shadow: 2px 2px 8px rgba(0,0,0,0.2) !important; Adds a subtle shadow for depth */
  /* margin: 0; */
  font-size: 40px; /* Adjust the size as needed */
  color: #007bff;
  margin-left: 6%;
}

.total {
  margin-right: 6%; /* Ensure there's some space on the right */
  color: #007bff; /* Subtle text color for total count */
  font-weight: 600;
}

.sub-category-buttons {
    text-align: center; /* Center-aligns the buttons */
    margin-bottom: 20px; /* Adds some space below the buttons */
  }
  
  .sub-category-buttons button {
    margin: 0 10px 20px 10px; /* Adds space around buttons */
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Adds transition for color and transform */
  }
  
  .sub-category-buttons button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
    transform: scale(1.05); /* Slightly enlarges the button */
  }

  .active-product-type-button {
    background-color: #e73a56 !important; /* A different, darker blue for active buttons */
    color: white;
    border: 2px solid #002752; /* Optional: a darker border for active buttons */
  }
  
.products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the items horizontally */
    gap: 20px; /* Space between cards */
  }
  
  .product-card {
    display: flex;
    flex-direction: column; /* Stack contents vertically */
    align-items: center; /* Center contents horizontally */
    text-align: center; /* Center text */
    border: 1px solid #ddd; /* Border around cards */
    padding: 10px; /* Padding inside cards */
    width: 240px; /* Adjusted width for better layout */
    height: 380px; /* Adjusted height to accommodate image and text */
    overflow: hidden; /* Hide overflow content */
    background: #fff; /* Card background color */
  }
  
  .product-card img {
    width: 100%; /* Make images full width of the card */
    height: 50%; /* Fixed height for images */
    object-fit: cover; /* Cover the area with the image without stretching */
    margin-bottom: 10px; /* Space between image and text */
  }
  
  .product-card h2, .product-card p {
    margin: 5px 0; /* Adjusted margins for consistency */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
    white-space: nowrap; /* Keep text on one line */
    display: block; /* Ensure these are block level for correct overflow handling */
    width: 100%; /* Full width to align with parent's padding */
  }

  .back-icon_gendercategory {
    margin-left: 4%; /* Aligns it to the left */
    font-size: 20px;
    cursor: pointer;
    color: #007bff;
    font-weight: 600;
  }
  
  .back-icon_gender:hover {
    color: #0056b3; /* A darker shade of blue on hover */
  }