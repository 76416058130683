/* ProductDetails.css */
.screenshot-ready {
  position: relative; /* Ensures container doesn't shift */
}

.screenshot-ready .qr-code, .screenshot-ready .product-details-table {
  position: static; /* Overrides any other positioning during screenshot */
  font-size: 16px; /* Larger font size for clarity */
  line-height: 2.5 !important; /* Improved line height */
}

.screenshot-table th, .screenshot-table td {
  padding: 8px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



