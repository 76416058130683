/* Enhancements for actions within the product card */
.actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 60px;
  }
  
  .actions button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .actions button:hover {
    background-color: #0056b3;
  }
  
  .actions button:active {
    transform: scale(0.95);
  }
  
  .fa-icon {
    margin-right: 5px;
    font-size: 20px;
  }
  
  /* This ensures buttons have no outline when focused and have a slight 'press' effect */
  button:focus {
    outline: none;
  }