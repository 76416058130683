.back-icon {
    position: absolute;
    top: 2% !important; /* Adjust based on your header/nav height */
    left: 2%; /* Distance from the left edge of the page */
    cursor: pointer; /* Changes the cursor to indicate it's clickable */
    color: #007bff; /* Blue, but choose whatever color fits your design */
    font-weight: 600;
    font-size: 20px; /* Makes the icon slightly larger */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .back-icon:hover {
    color: #0056b3; /* A darker shade of blue on hover */
  }