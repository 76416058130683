/* .image-box {
    width: 70%; 
    height: 100%; 
    position: relative;
    
  }
  
  .image-box img {
    max-width: 100%; 
    height: auto;
    border-radius: 10px; 
  } */
  
 

  .landing-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }
  
  .content-section {
    width: 70%;
    height: 100vh; /* Explicit height to serve as reference */
    position: relative;
  }
  
  .logo-section {
    width: 30%;
    height: 100vh; /* Ensure logo section fills the height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content-section div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 15px;
    width: 100%;
    height: 100%;
  }
  
  .content-section div::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Initially transparent */
    transition: background-color 0.3s ease;
    border-radius: 15px; /* Match your images' border-radius */
  }
  
  .content-section div:hover::before {
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  }
  
  .content-section div img {
    transition: opacity 0.3s ease;
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  .image-label {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    color: white;
    font-size: 24px;
    pointer-events: none;
  }
  
  .content-section div:hover .image-label {
    opacity: 1;
    visibility: visible;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .content-section div {
    animation: fadeIn 2s ease-out forwards;
  }
  