/* Full page container ensuring vertical and horizontal centering */
.container_signin {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    flex-wrap: wrap;
    min-height: 100vh; /* Minimum height to take full viewport height */
    width: 100%; /* Full width to ensure it takes the full viewport width */
    margin: 0 auto; /* Helps in further centering and adjusting */
    padding: 0 15px; /* Padding for some breathing room around the edges */
    box-sizing: border-box; /* Makes sure padding and border are included in width/height */
}

.form-container_signin {
    width: 100%;
    max-width: 420px; /* Maximum width for the sign-in form */
    padding: 20px; /* Padding inside the form container */
    border: 1px solid #333; /* Border for visibility and design */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for a subtle depth effect */
    background: white; /* Background color */
    z-index: 1000; /* Ensures it's on top if anything else is on the page */
}

/* Styles for input boxes */
.searchbox {
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    color: #4a5568;
    background-color: #ffffff;
    border: 1px solid #d2d6dc;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
}

/* Styles for buttons */
.button {
    width: 100%;
    background-color: #3182ce;
    color: white;
    padding: 14px 28px;
    font-size: 14px;
    font-weight: medium;
    text-transform: uppercase;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.button:hover {
    background-color: #63b3ed;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.button:active {
    background-color: #2b6cb0;
}

/* Responsive adjustments for form elements */
@media (max-width: 640px) {
    .form-container_signin {
        padding: 15px;
    }

    .searchbox,
    .button {
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .form-container_signin {
        width: 60%;
    }
}

@media (min-width: 1024px) {
    .form-container_signin {
        width: 50%;
    }
}

/* Responsive handling for profile image */
.profile-img {
    width: 100%;
    max-width: 100px;
    height: auto;
    display: block;
    margin: 10px auto;
}
