body {
    background-color: 	#fff  !important ; /* Soft Gray */
  }

  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  
  h1 {
    flex: 1;
    font-weight: 600; /* Makes the title bold */
    text-align: center; /* Center-aligns the title */
    color: #007bff; /* Changes the color to a shade of blue */
    /* text-shadow: 2px 2px 8px rgba(0,0,0,0.2) !important; Adds a subtle shadow for depth */
    /* margin: 0; */
    font-size: 40px; /* Adjust the size as needed */
    color: #007bff;
    margin-left: 6%;
  }
  
  .total {
    margin-right: 6%; /* Ensure there's some space on the right */
    color: #007bff; /* Subtle text color for total count */
    font-weight: 600;
  }
  
  .sub-category-buttons {
    text-align: center; /* Center-aligns the buttons */
    margin-bottom: 20px; /* Adds some space below the buttons */
  }
  
  .sub-category-buttons button {
    margin: 0 10px 20px 10px; /* Adds space around buttons */
    background-color: #007bff; /* Blue background */
    color: white; /* White text */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Adds transition for color and transform */
  }
  
  .sub-category-buttons button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
    transform: scale(1.05); /* Slightly enlarges the button */
  }

  .active-category-button {
    background-color: #004085; /* Example: a different shade of blue */
    border: 2px solid #002752; /* Example: a darker border */
    color: white;
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Creates 6 equal columns */
    gap: 20px !important; /* Adjusts the gap between grid items */
    justify-content: center;
    padding: 0 20px; /* Add padding to ensure there's space around the grid */
    max-width: 1600px; /* Adjust based on your design needs */
    margin: auto; /* Center the grid in the parent container */
  }
  
  .product-card {
    border: 1px solid #ddd;
    padding: 15px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    background-color: #230e4a14 !important;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
  
  .product-card img {
    width: 100%; /* Ensures the image takes full width */
    flex-grow: 1; /* Allows the image to grow and fill the container */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    border-radius: 5px 5px 5px 5px; /* Adjusts border-radius to only top corners */
}
  
.product-card h2, .product-card p {
  margin: 10px 0; /* Example margin, adjust as needed */
}
  
  .product-card p {
    font-size: 14px; /* Adjusts paragraph font size for consistency */
    color: #666; /* Lighter font color for less emphasis */
    margin: 5px 0; /* Controls spacing around paragraphs */
  }

  .image-placeholder {
    width: 100%;
    height: 200px; /* Match the height of your images */
    background-color: #f0f0f0; /* Light grey background */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc; /* Light grey text */
    border-radius: 5px; /* Optional: Rounds the corners */
    font-weight: bold;
    flex-grow: 1;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
  }

    .back-icon_category {
      margin-left: 4%; /* Aligns it to the left */
      font-size: 20px;
      cursor: pointer;
      color: #007bff;
      font-weight: 600;
    }

.back-icon_category:hover {
  color: #0056b3; /* A darker shade of blue on hover */
}

.products-grid + div { /* Adjust the class as per your structure */
    margin-top: 2%; /* Creates space above the pagination */
  }