.container_password {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh; /* Ensures the container takes at least the full height of the viewport */
    width: 100%;
    padding: 0 15px; /* Provides padding to prevent content from touching the edges */
    box-sizing: border-box; /* Includes padding and border in the width and height */
}

/* Form container for styling the form area */
.form-container_password {
    width: 100%;
    max-width: 420px; /* Suitable width for the form */
    margin: 0 auto; /* Centers the form horizontally */
    padding: 20px;
    border: 1px solid #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
}

/* Styling for input fields */
.searchbox {
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    color: #4a5568;
    background-color: #ffffff;
    border: 1px solid #d2d6dc;
    border-radius: 6px;
    margin-bottom: 15px; /* Adds space between input fields */
}

/* Button styling */
.button {
    width: 100%;
    background-color: #3182ce;
    color: white;
    padding: 14px 28px;
    font-size: 14px;
    font-weight: medium;
    text-transform: uppercase;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background 0.3s, box-shadow 0.3s;
}

.button:hover {
    background-color: #63b3ed;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.button:active {
    background-color: #2b6cb0;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .form-container_password {
        padding: 15px;
    }

    .searchbox,
    .button {
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .form-container_password {
        width: 60%;
    }
}

@media (min-width: 1024px) {
    .form-container_password {
        width: 50%;
    }
}

/* Profile image styles */
.profile-img {
    width: 100%;
    max-width: 120px; /* Sets a maximum width for the profile image */
    height: auto; /* Maintains aspect ratio */
    display: block;
    margin: 10px auto; /* Centers the image horizontally */
}